<template>
    <v-card>
        <v-card-title>Settings</v-card-title>
        <v-tabs background-color="#fff" color="#000" center-active v-model="tab">
            <v-tab>Change Password</v-tab>
            <v-tab>Manage Price</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item class="box-center">
                <v-card flat width="380">
                    <v-card-text>

                        <v-text-field v-model="password" :type="isPasswordVisible ? 'text' : 'password'"
                            :disabled="isOtpSent" label="New Password"
                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                            hide-details="auto" class="mb-6" @click:append="isPasswordVisible = !isPasswordVisible">
                        </v-text-field>

                        <v-text-field v-model="confirmPassword" :type="isConfirmPasswordVisible ? 'text' : 'password'"
                            :disabled="isOtpSent" label="Confirm Password" hide-details="auto" class="mb-6"
                            :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                            @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible">
                        </v-text-field>

                        <div class="otp-box">
                            <div class="otp-inner-box">
                                <v-otp-input length="6" plain type="number" v-model="otp"
                                    :disabled="isOtpVerified"></v-otp-input>
                                <v-btn text color="primary" v-if="!isOtpSent" :disabled="isOtpSent" @click="generateOtp"
                                    :loading="isGenerating">Generate OTP</v-btn>
                                <v-btn text color="primary" v-if="isOtpSent && !isOtpVerified" @click="verifyOtp"
                                    :loading="isVerifying">Verify</v-btn>
                                <v-btn text color="primary" v-if="isOtpVerified" disabled>Verified</v-btn>
                            </div>
                            <div v-if="isOtpSent && !isOtpVerified">
                                <small class="resend" @click="regenerateOtp">
                                    <v-icon size="15">{{ icons.mdiReload }}</v-icon>
                                    resend
                                </small>
                            </div>
                        </div>

                        <p :class="['mt-10 message', isError ? 'error' : 'success']">{{ message }}</p>
                        <v-btn color="primary" width="100%" class="mt-12" :disabled="!isOtpVerified"
                            @click="changePassword">Update</v-btn>

                    </v-card-text>
                </v-card>
            </v-tab-item>

        </v-tabs-items>


    </v-card>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { mdiEyeOffOutline, mdiEyeOutline, mdiReload } from "@mdi/js";
import { useUser } from '@/composables/user/user'
import axios from '@axios'

export default {

    setup() {
        const message = ref('')
        const isGenerating = ref(false)
        const isVerifying = ref(false)
        const isError = ref(false)
        const isOtpSent = ref(false)
        const isOtpVerified = ref(false)
        const tab = ref(null)
        const otp = ref(0)
        const password = ref('')
        const confirmPassword = ref('')
        const isPasswordVisible = ref(false)
        const isConfirmPasswordVisible = ref(false)
        const { state: userState } = useUser()
        const items = ref(['web', 'shopping', 'videos', 'images', 'news'])
        const text = ref(`Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`)
        const priceDetails = ref()

        const generateOtp = async () => {
            if (password.value == confirmPassword.value) {
                if (confirmPassword.value.length <= 8) {
                    console.log('Password length must be 8 or greater')
                    isError.value = true
                    message.value = `Password length must be 8 or greater`
                    hideMessage()
                    return
                }
                try {
                    isGenerating.value = true
                    await axios.post('/admin/send-otp')
                    isGenerating.value = false
                    isOtpSent.value = true
                    isError.value = false
                    message.value = `OTP sent to ${userState.info.email}`
                    hideMessage()
                } catch (err) {
                    console.log(err)
                }

            }
            else {
                message.value = '**Password & Confirm Password Not Same**'
                isError.value = true
                hideMessage()
                isGenerating.value = false
            }
        }

        const regenerateOtp = async () => {
            if (password.value == confirmPassword.value) {

                try {
                    isGenerating.value = true
                    await axios.post('/admin/send-otp')
                    isGenerating.value = false
                    isOtpSent.value = true
                    isError.value = false
                    message.value = `OTP sent to ${userState.info.email}`
                    hideMessage()
                } catch (err) {
                    console.log(err)
                }

            }
            else {
                message.value = '**Password & Confirm Password Not Same**'
                isError.value = true
                hideMessage()
                isGenerating.value = false
            }
        }

        const verifyOtp = async () => {
            if (otp.value != '' && otp.value.length == 6) {
                try {
                    isVerifying.value = true
                    await axios.post('/admin/verify-otp', {
                        otp: otp.value
                    })
                    isVerifying.value = false
                    isOtpVerified.value = true
                } catch (err) {
                    console.log(err)
                }
            }
            else {
                isError.value = true
                message.value = '** Please Enter OTP **'
                hideMessage()
                isVerifying.value = false
            }
        }

        const changePassword = async () => {
            if (password.value == confirmPassword.value) {
                try {
                    isGenerating.value = true
                    await axios.patch('/admin/change-password', { password: confirmPassword.value })
                    isError.value = false
                    message.value = `Password Changed`
                    hideMessage()
                } catch (err) {
                    console.log(err)
                }
                finally {
                    password.value = ''
                    confirmPassword.value = ''
                    otp.value = ''
                    isOtpSent.value = false
                    isOtpVerified.value = false
                    isVerifying.value = false
                }
            }
            else {
                message.value = '**Password & Confirm Password Not Same**'
                isError.value = true
                hideMessage()
                isGenerating.value = false
            }
        }

        const hideMessage = () => {
            setTimeout(() => {
                message.value = ''
            }, 3000)
        }

        const setPriceDetails = (data) => {
            priceDetails.value = data
        }

        const getPricing = async () => {
            const pricingRes = await axios.get('/wallet/pricing')
            setPriceDetails(pricingRes.data)
            return
        }

        onMounted(() => getPricing())

        return {
            regenerateOtp,
            isGenerating,
            isVerifying,
            isError,
            message,
            generateOtp,
            verifyOtp,
            userState,
            tab,
            items,
            text,
            otp,
            isOtpSent,
            isOtpVerified,
            password,
            confirmPassword,
            isPasswordVisible,
            isConfirmPasswordVisible,
            changePassword,
            priceDetails,
            icons: {
                mdiEyeOutline,
                mdiEyeOffOutline,
                mdiReload
            },
        }

    }
}
</script>

<style scoped>
.error {
    color: red;
}

.success {
    color: green;
}

.message {
    width: 100%;
    font-size: 15px;
    text-align: center;
    background: transparent !important;
}

.box-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    height: 77vh;
}

.otp-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.otp-inner-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.resend {
    color: blue;
    font-size: 15px;
    margin-top: -10px;
}
</style>